<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Diğer Gider</template>
    <template slot="body">
      <form>
        <v-row dense v-if="originalData.id">
          <v-col sm="6">
            <rs-text-field
              label="Diğer Gider Türü"
              :value="originalData.income_type.name"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="6" v-if="originalData.resident">
            <rs-text-field
              label="Sakin"
              :value="originalData.resident.name"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="6" v-if="originalData.amount">
            <rs-money-field
              label="Tutar"
              :value="originalData.amount"
              :disabled="disabled"
            />
          </v-col>
          <v-col sm="6" v-if="originalData.income_type_id">
            <rs-text-field
              label="Diğer Gider Türü"
              :value="originalData.income_type.name"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="6" v-if="originalData.spent_on">
            <rs-text-field
              label="İşlem Tarihi"
              :value="new Date(originalData.spent_on).toLocaleDateString()"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col
            sm="6"
            v-if="originalData.bank_account_id || originalData.safe_id"
            class="cursor-pointer"
            @click="
              $router.push(
                originalData.bank_account_id
                  ? {
                      name: 'definitions.bank-accounts.edit',
                      params: { id: originalData.bank_account_id },
                    }
                  : {
                      name: 'definitions.safes.edit',
                      params: { id: originalData.safe_id },
                    }
              )
            "
          >
            <rs-select-collecting
              label="Banka / Kasa"
              :bank-account-id.sync="originalData.bank_account_id"
              :safe-id.sync="originalData.safe_id"
              :readonly="disabled"
              :filled="disabled"
              :required="disabled"
            />
          </v-col>
          <v-col
            sm="6"
            v-if="originalData.resident_id"
            class="cursor-pointer"
            @click="
              $router.push({
                name: 'definitions.residents.edit',
                params: { id: originalData.resident_id },
              })
            "
          >
            <rs-select-resident
              label="İlgili Sakin"
              :cluster-id="clusterId"
              :value="originalData.resident_id"
              :readonly="disabled"
              :filled="disabled"
              :required="disabled"
            />
          </v-col>
          <v-col
            sm="6"
            v-if="originalData.house_id"
            class="cursor-pointer"
            @click="
              $router.push({
                name: 'definitions.houses.edit',
                params: { id: originalData.house_id },
              })
            "
          >
            <rs-text-field
              label="İlgili Bağımsız Bölüm"
              :value="originalData.house.door_number"
              :readonly="disabled"
              :filled="disabled"
              :required="disabled"
            />
          </v-col>
          <v-col sm="6" v-if="originalData.refund_type_id">
            <rs-text-field
              label="Avans İade Nedeni"
              :value="originalData.payback_reason.name"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <v-row dense v-if="originalData.id && originalData.collecting">
          <v-col class="elevation-2 mt-3 p-3">
            <h5>İlgili Tahsilat</h5>
            <p>Tutar: {{ originalData.collecting.amount.toLocaleString() }}₺</p>
            <p>
              Tarih:
              {{
                new Date(
                  originalData.collecting.collected_on
                ).toLocaleDateString()
              }}
            </p>
            <p>Açıklama: {{ originalData.collecting.description }}</p>
          </v-col>
        </v-row>

        <v-row dense v-if="originalData.id && originalData.debt">
          <v-col class="elevation-2 mt-3 p-3">
            <h5>İlgili Borç</h5>
            <p>Tutar: {{ originalData.debt.amount.toLocaleString() }}₺</p>
            <p v-if="originalData.debt.deferment_amount">
              Gecikme:
              {{ originalData.debt.deferment_amount.toLocaleString() }}₺
            </p>
            <p>Açıklama: {{ originalData.debt.description }}</p>
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-submit
          hide-save-close
        />
      </form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    show(id, disabled) {
      this.formData = {};
      this.originalData = {};
      this.stopDeferment = false;
      this.disabled = false;
      this.id = null;

      this.$refs.sidePanel.show();

      if (id) {
        this.id = id;
      }

      if (disabled) {
        this.disabled = true;
      }

      this.load();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      if (this.isLoading || !this.id) return false;

      this.isLoading = true;
      this.selectedItems = [];

      this.$api
        .query(`expense/other-expenses/${this.id}`)
        .then((response) => {
          this.loadData(response);

          if (this.originalData.amount) {
            document.getElementsByClassName("formatted-text")[0].innerHTML =
              this.numberToLocaleFormat(this.originalData.amount);
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
