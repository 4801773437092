<template>
  <v-data-table
    v-if="bankAccountId || safeId || providerId"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
  >
    <template v-slot:top>
      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteClick"
        @cancelled="$refs.confirmDelete.hide()"
      />

      <rs-table-title-bar
        v-if="$refs.headSearch && $refs.confirmDelete"
        class="mt-6"
        title="Hesap Hareketleri"
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        @click:edit="handleEditClick"
        :edit-enabled="selectedItems.length === 1"
        :search.sync="search.query"
        :delete-enabled="deleteEnabled"
        show-delete=""
        @click:delete="
          $refs.confirmDelete.show('Silmek istediğinizden emin misiniz?')
        "
        v-bind="titleBarAttrs"
      />

      <CollectingDetail ref="collectingDetailForm" />
      <DebtForm ref="debtForm" />
      <InvoicePaymentForm ref="invoicePaymentForm" />
      <FirstBalanceForm ref="firstBalanceForm" @saved="loadList()" />
      <OtherExpenseForm ref="otherExpenseForm" />
      <OtherIncomePaymentForm ref="otherIncomePaymentForm" />
      <TransferDetail ref="transferDetail" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.append="{ headers }">
      <rs-table-foot-totals :headers="headers" :totals="footTotals" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <a href="#" @click.prevent="handleEditClick(item)">
        <i class="menu-icon mdi mdi-pencil"></i>
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.balance_no="{ item, value }">
      <a href="#" @click.prevent="handleBBEditClick(item)">
        <rs-table-cell-string :value="value" limit="20" />
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.provider_name="{ item }">
      <router-link
        v-if="item.provider_id && can('see-cluster-provider')"
        :to="{
          name: can('edit-cluster-provider')
            ? 'definitions.cluster-providers.edit'
            : 'definitions.cluster-providers.show',
          params: { id: item.provider_id },
        }"
      >
        {{ item.provider_name }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.resident="{ item }">
      <router-link
        v-if="item.resident_id && can('see-resident')"
        :to="{
          name: can('edit-resident')
            ? 'definitions.residents.edit'
            : 'definitions.residents.show',
          params: { id: item.resident_id },
        }"
      >
        {{ item.resident }}
      </router-link>

      <template v-else>{{ item.resident }}</template>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.action_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.provider_debt="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.provider_due="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.provider_balance="{ value }">
      <rs-table-cell-balance :value="value" colored />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.bank_safe_debt="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.bank_safe_due="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.bank_safe_balance="{ value }">
      <rs-table-cell-balance :value="value" colored />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.description="{ value }">
      <rs-table-cell-string :value="value" limit="40" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasExport, hasDataTable, hasPermissions } from "@/view/mixins";
import {
  CollectingDetail,
  OtherIncomePaymentForm,
} from "@/view/pages/income/forms";
import DebtForm from "@/view/pages/income/forms/DebtForm";
import FirstBalanceForm from "@/view/content/safes/FirstBalanceForm";
import InvoicePaymentForm from "@/view/pages/expense/forms/InvoicePaymentForm";
import OtherExpenseForm from "@/view/pages/expense/forms/OtherExpenseForm";
import TransferDetail from "@/view/pages/activities/TransferDetail";

export default {
  components: {
    CollectingDetail,
    DebtForm,
    FirstBalanceForm,
    InvoicePaymentForm,
    OtherExpenseForm,
    OtherIncomePaymentForm,
    TransferDetail,
  },
  mixins: [hasExport, hasDataTable, hasPermissions],
  props: {
    bankAccountId: {
      type: Number,
      required: true,
    },
    safeId: {
      type: Number,
      required: true,
    },
    providerId: {
      type: Number,
      required: true,
    },
    scopes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
    deleteEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (this.selectedItems[index].enforcement_transaction_id) {
          return false;
        }
      }

      return true;
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    bankAccountId: {
      handler() {
        this.loadBankAccount();
        this.loadList();
      },
      immediate: true,
    },
    safeId: {
      handler() {
        this.loadSafe();
        this.loadList();
      },
      immediate: true,
    },
    providerId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      bankAccount: null,
      safe: null,
      showFirstBalanceForm: false,
      options: {
        sortBy: ["action_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Sistem No",
          value: "balance_no",
          searchable: "text",
        },
        {
          text: "Belge No(Fatura-Fiş No)",
          value: "document_no",
          searchable: "text",
          hide: () => this.scopes.indexOf("provider") === -1,
        },
        {
          text:
            this.scopes.indexOf("bank_account") !== -1
              ? "Banka Hesap Kodu"
              : "Kasa Hesap Kodu",
          value: "bank_safe",
          searchable: "text",
          hide: () =>
            this.scopes.indexOf("safe") === -1 &&
            this.scopes.indexOf("bank_account") === -1,
          width: "120px",
        },
        {
          text: this.$t("labels.date"),
          value: "action_on",
          searchable: "date",
        },
        {
          text: "Fiş Türü",
          value: "bill_type",
          searchable: "text",
        },
        {
          text: "Borç Tutar",
          value: "provider_debt",
          searchable: "number",
          align: "end",
          hide: () => this.scopes.indexOf("provider") === -1,
        },
        {
          text: "Alacak Tutar",
          value: "provider_due",
          searchable: "number",
          align: "end",
          hide: () => this.scopes.indexOf("provider") === -1,
        },
        {
          text: this.$t("labels.balance"),
          value: "provider_balance",
          searchable: "number",
          align: "end",
          hide: () => this.scopes.indexOf("provider") === -1,
        },
        {
          text: "Borç Tutar",
          value: "bank_safe_debt",
          searchable: "number",
          align: "end",
          hide: () =>
            this.scopes.indexOf("safe") === -1 &&
            this.scopes.indexOf("bank_account") === -1,
        },
        {
          text: "Alacak Tutar",
          value: "bank_safe_due",
          searchable: "number",
          align: "end",
          hide: () =>
            this.scopes.indexOf("safe") === -1 &&
            this.scopes.indexOf("bank_account") === -1,
        },
        {
          text:
            this.scopes.indexOf("provider") !== -1
              ? "Fatura Kalan"
              : this.$t("labels.balance"),
          value: "bank_safe_balance",
          searchable: "number",
          align: "end",
          hide: () =>
            this.scopes.indexOf("safe") === -1 &&
            this.scopes.indexOf("bank_account") === -1,
        },
        {
          text: this.$t("labels.description"),
          value: "description",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Tedarikçi",
          value: "provider_name",
          searchable: "text",
          hide: () => this.scopes.indexOf("provider") !== -1,
        },
        {
          text: "Sakin",
          value: "resident",
          searchable: "text",
          hide: () => this.scopes.indexOf("provider") !== -1,
        },
        {
          text: "Blok",
          value: "block",
          searchable: "text",
          hide: () => this.scopes.indexOf("provider") !== -1,
        },
        {
          text: "BB",
          value: "door_number",
          searchable: "text",
          hide: () => this.scopes.indexOf("provider") !== -1,
        },
        {
          text: "Yıl",
          value: "year",
          searchable: "number",
          hide: () =>
            this.scopes.indexOf("safe") !== -1 ||
            this.scopes.indexOf("bank_account") !== -1 ||
            this.scopes.indexOf("provider") !== -1,
        },
        {
          text: "Ay",
          value: "month",
          searchable: "number",
          hide: () =>
            this.scopes.indexOf("safe") !== -1 ||
            this.scopes.indexOf("bank_account") !== -1 ||
            this.scopes.indexOf("provider") !== -1,
        },
      ],
      titleBarAttrs: {
        exportUrl: "balance-activities",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.scope_and = this.scopes;

      if (this.bankAccountId) {
        params.bank_account_id = this.bankAccountId;
      } else if (this.safeId) {
        params.safe_id = this.safeId;
      } else if (this.providerId) {
        params.provider_id = this.providerId;
      } else {
        return;
      }

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (
        this.isLoading ||
        (!this.safeId && !this.bankAccountId && !this.providerId) ||
        !this.clusterId
      ) {
        return;
      }
      this.list = [];
      this.selectedItems = [];

      const params = this.getParams();

      this.isLoading = true;

      this.$api
        .query("balance-activities", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
            this.$emit("update:item-count", this.total);
            this.$emit("update:totals", this.footTotals);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    loadBankAccount() {
      if (!this.bankAccountId) {
        this.bankAccount = null;
        return false;
      }

      this.$api
        .get("bank-accounts", this.bankAccountId)
        .then((response) => {
          this.bankAccount = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    loadSafe() {
      if (!this.safeId) {
        this.safe = null;
        return false;
      }

      this.$api
        .get("safes", this.safeId)
        .then((response) => {
          this.safe = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    loadBankAccountDetail(bankAccountId, id, type) {
      this.$api
        .get("bank-accounts", bankAccountId)
        .then((response) => {
          this.$refs.firstBalanceForm.show(
            id,
            {
              bankAccount: response.data.data,
            },
            type === "edit" ? false : true
          );
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleEditClick(item) {
      if (!item.id) {
        item = this.selectedItems[0];
      }

      if (item.type === "collecting") {
        this.$router.push({
          name: "incomes.houses.show",
          params: { id: item.house_id },
        });
      } else if (item.type === "debt") {
        this.$refs.debtForm.show(item.debt_id, true);
      } else if (item.type === "invoice_payment" || item.type === "invoice") {
        this.$router.push({
          name: "expenses.invoices.edit",
          params: { id: item.invoice_id },
        });
      } else if (item.type === "loss") {
        this.$refs.otherExpenseForm.show(item.other_expense_id, true);
      } else if (item.type === "payback") {
        this.$refs.otherExpenseForm.show(item.other_expense_id, true);
      } else if (item.type === "other_income_payment") {
        this.isLoading = true;

        this.$api
          .get(`income/payments/${item.other_income_payment_id}`)
          .then((response) => {
            this.$router.push({
              name: "incomes.other.edit",
              params: { id: response.data.data.other_income_id },
            });
          })
          .finally(() => (this.isLoading = false));
      } else if (item.type === "transfer") {
        this.$refs.transferDetail.show(item.transfer_id);
      } else if (item.type === "openning") {
        this.loadBankAccountDetail(item.bank_account_id, item.id, "edit");
      } else if (item.type === "other_income") {
        this.$router.push({
          name: "incomes.other.edit",
          params: { id: item.other_income_id },
        });
      }
    },
    handleBBEditClick(item) {
      if (!item.id) {
        item = this.selectedItems[0];
      }

      if (item.enforcement_transaction_id) {
        this.$router.push({
          name: "enforcements.show",
          params: { id: item.enforcement_id },
        });
      } else if (item.type === "collecting") {
        this.$refs.collectingDetailForm.show(item.collecting_id, true);
      } else if (item.type === "debt") {
        this.$refs.debtForm.show(item.debt_id, true);
      } else if (item.type === "invoice_payment" || item.type === "invoice") {
        this.$router.push({
          name: "expenses.invoices.show",
          params: { id: item.invoice_id },
        });
      } else if (item.type === "loss") {
        this.$refs.otherExpenseForm.show(item.other_expense_id, true);
      } else if (item.type === "payback") {
        this.$refs.otherExpenseForm.show(item.other_expense_id, true);
      } else if (item.type === "transfer") {
        this.$refs.transferDetail.show(item.transfer_id);
      } else if (item.type === "other_income_payment") {
        this.isLoading = true;

        this.$api
          .get(`income/payments/${item.other_income_payment_id}`)
          .then((response) => {
            this.$router.push({
              name: "incomes.other.show",
              params: { id: response.data.data.other_income_id },
            });
          })
          .finally(() => (this.isLoading = false));
      } else if (item.type === "openning") {
        this.loadBankAccountDetail(item.bank_account_id, item.id, "");
      } else if (item.type === "other_income") {
        this.$router.push({
          name: "incomes.other.show",
          params: { id: item.other_income_id },
        });
      }
    },
    handleDeleteItem(item) {
      if (item.type === "debt") {
        return this.deleteDebt(item.debt_id).catch((error) => {
          this.handleError(error);
        });
      } else if (item.type === "collecting") {
        return this.deleteCollecting(item.collecting_id).catch((error) => {
          this.handleError(error);
        });
      } else if (["payback", "loss"].indexOf(item.type) !== -1) {
        return this.deleteOtherExpense(item.other_expense_id).catch((error) => {
          this.handleError(error);
        });
      } else if (item.type === "openning") {
        return this.deleteBalanceActivity(item.id).catch((error) => {
          this.handleError(error);
        });
      } else if (item.type === "invoice_payment") {
        return this.deleteInvoicePayment(item.invoice_payment_id).catch(
          (error) => {
            this.handleError(error);
          }
        );
      } else if (item.type === "other_income") {
        return this.deleteOtherIncome(item.other_income_id).catch((error) => {
          this.handleError(error);
        });
      } else if (item.type === "other_income_payment") {
        return this.deleteOtherIncomePayment(
          item.other_income_payment_id
        ).catch((error) => {
          this.handleError(error);
        });
      } else if (item.type === "transfer") {
        return this.deleteTransfers(item.transfer_id).catch((error) => {
          this.handleError(error);
        });
      } else if (item.type === "invoice") {
        return this.deleteInvoice(item.invoice_id).catch((error) => {
          this.handleError(error);
        });
      }

      // eslint-disable-next-line
      console.error("Invalid type: " + item.type);

      return null;
    },
    async handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;
      for (const index in this.selectedItems) {
        const item = this.selectedItems[index];
        await this.handleDeleteItem(item);
      }

      this.$toast.success("Silindi");
      this.isLoading = false;
      this.loadList();
      this.$refs.confirmDelete.hide();
    },
    deleteTransfers(transfer_id) {
      if (!transfer_id) {
        return Promise.reject();
      }

      return this.$api.delete(`income/transfers/${transfer_id}`);
    },
    deleteBalanceActivity(balanceActivityId) {
      return this.$api
        .delete(`balance-activities/${balanceActivityId}`)
        .then(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
          this.loadList();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteCollecting(collectingId) {
      if (!collectingId) {
        return Promise.reject();
      }

      return this.$api.delete(`income/collectings/${collectingId}`);
    },
    deleteDebt(debtId) {
      if (!debtId) {
        return Promise.reject();
      }

      return this.$api.delete(`income/debts/${debtId}`);
    },
    deleteOtherExpense(otherExpenseId) {
      if (!otherExpenseId) {
        return Promise.reject();
      }

      return this.$api.delete(`expense/other-expenses/${otherExpenseId}`);
    },
    deleteOtherIncome(id) {
      if (!id) {
        return Promise.reject();
      }

      return this.$api.delete(`income/other-incomes/${id}`);
    },
    deleteOtherIncomePayment(id) {
      if (!id) {
        return Promise.reject();
      }

      return this.$api.delete(`income/payments/${id}`);
    },
    deleteInvoicePayment(paymentId) {
      if (!paymentId) {
        return Promise.reject();
      }

      return this.$api.delete(`expense/payments/${paymentId}`);
    },
    deleteInvoice(paymentId) {
      if (!paymentId) {
        return Promise.reject();
      }

      return this.$api.delete(`expense/invoices/${paymentId}`);
    },
  },
};
</script>
